import React, { useEffect, useState } from 'react'
import { getQuestions } from "../api/questionsApi"
import { QuestionPres } from "./QuestionPres"
import { Question } from "../../types/Question"

export const QuestionsContainer = () => {
    const [questions, setQuestions] = useState<Question[]>([])
    const [currentQuestion, setCurrentQuestion] = useState<number>(0)

    useEffect(() => {
        if (questions.length === 0)
            getQuestions()
                .then(questions => setQuestions(questions))
    }, [questions.length])

    const getNextQuestion = () => {
        setCurrentQuestion((currentQuestion + 1 ) % questions.length)
    }

    if (questions.length === 0)
        return <div>Loading...</div>

    return <div id={"questions-container"}>
        <QuestionPres question={questions[currentQuestion]}/>
        <div id={"questions-container-btn-group"}>
            <button onClick={getNextQuestion}>
                Nächste, komm.
            </button>
        </div>
    </div>
}
