import React from 'react'

export const DisclaimerContent = () => <div id={"disclaimer-content"}>
    <div>
        Der "5 schnelle Fragen an"-Generator, mit Fragen aus<br/>
        <div>Gemischtes Hack.</div>
    </div>
    <div>
        Absolut <span className={"emphasize"}>inoffiziell</span> und
        <span className={"emphasize"}> nicht authorisiert</span> von der Gemischtes Hack GbR.
    </div>
    <div>
        Keine Urheberrechtsverletzung beabsichtigt.
    </div>
    <div>
        Keine Cookies, Tracking und keine Werbung hier. Nur Fragen.
    </div>
</div>

export const Disclaimer = ({ onAccept }: { onAccept: () => void }) => {
    return <div id={"disclaimer"}>
        <DisclaimerContent />
        <button onClick={onAccept}>Verstanden.</button>
    </div>
}
