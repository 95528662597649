import React, { useState } from 'react'
import { Disclaimer } from "./Disclaimer"
import { QuestionsContainer } from "../components/QuestionsContainer"

export const Navigator = () => {
    const [readDisclaimer, setReadDisclaimer] = useState(false)

    if (!readDisclaimer) {
        return <Disclaimer onAccept={() => setReadDisclaimer(true)}/>
    } else {
        return <QuestionsContainer />
    }
}
