import React from 'react'
import { Question } from "../../types/Question"

export interface QuestionPresProps {
    question: Question
}

export const QuestionPres: React.FC<QuestionPresProps> = ({ question }) => <div className={"question"}>
    <div className={"wording"}><div>"{question.wording}"</div></div>
    <div className={"question-meta"}>
        <div className={"episode"}>Folge #{question.episode}, Frage {question.id % 10}.</div>
        <div className={"questioner"}>Gefragt von {question.questioner}</div>
    </div>
</div>
